import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Container from '@mui/material/Container';
import { StyledEngineProvider } from '@mui/material/styles';

import ApiProvider from 'common/providers/ApiProvider';
import ThemeProvider from 'common/providers/ThemeProvider';
import DownloadSignedDocuments from 'pages/DownloadSignedDocument';
import GeneralErrorPage from 'pages/GeneralErrorPage';

import 'typeface-open-sans';

const HealthDeclaration = React.lazy(() => import('pages/HealthDeclaration'));
const SignDocuments = React.lazy(() => import('pages/SignDocuments'));
const AfterSigning = React.lazy(() => import('pages/AfterSigning'));

function App() {
  const { path, params } = useRouteMatch<{ token: string }>();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <ApiProvider token={params.token}>
          <Container>
            <React.Suspense fallback={null}>
              <Switch>
                <Route exact path={`${path}/health-declaration`}>
                  <HealthDeclaration />
                </Route>
                <Route exact path={`${path}/sign-documents`}>
                  <SignDocuments />
                </Route>
                <Route exact path='/after-signing'>
                  <AfterSigning />
                </Route>
                <Route exact path={`${path}/documents`}>
                  <DownloadSignedDocuments />
                </Route>
                <Route>
                  <GeneralErrorPage />
                </Route>
              </Switch>
            </React.Suspense>
          </Container>
        </ApiProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
